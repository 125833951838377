<template>
  <div class="page1">
    <EForm :formColumns="formColumns"
           :rowSize="4"
           :optionsBtn="true"
           :actionBtn="false"
           :formData="searchForm"
           ref="form"
           :searchFlag="true"
           url="boothOccupancyRateExport"
           fileName="出摊率"
    >
    </EForm>
    <div class="mt20 fs15">
      统计时间 ：{{createTime}}（当前日）
    </div>
    <ETable :tableTitle="tableTitle"
            :tableData="tableData"
            :needPagination="false"
            :showSummary="true"
            :getSummaries="getSummaries"
            :needIndex="false"
    >
    </ETable>

  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {dateFormat} from '@/assets/js/common'
  export default {
    name: 'boothRentalRateList',
    data() {
      return {
        tableTitle: [
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName'
          },
          {
            label: '数量汇总',
            prop: '',
            children: [
              {
                label: '总数',
                prop: 'totalCount'
              },
              {
                label: '已租数',
                prop: 'hasRentCount'
              },
              {
                label: '未租数',
                prop: 'notRentCount'
              },
            ]
          },
          {
            label: '面积汇总',
            prop: '',
            children: [
              {
                label: '总面积',
                prop: 'totalAreas'
              },
              {
                label: '已租面积',
                prop: 'hasRentAreas'
              },
              {
                label: '未租面积',
                prop: 'notRentAreas'
              },
              {
                label: '面积出租率',
                prop: 'occupancyRate'
              },
            ]
          },
        ],
        formColumns: [],
        tableData: [],
        searchForm: {
          boothCode: null,
          payTimeStart: null,
          payTimeEnd: null,
          areaId: null,
          placeId: null,
          chargePersonName: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        form: {},
        sumData:{
          totalCount:0,
          hasRentCount:0,
          notRentCount:0,
          totalAreas:0,
          hasRentAreas:0,
          notRentAreas:0,
          occupancyRate:0
        },
        createTime:''
      }
    },
    watch: {},
    created() {
      this.getData()
      this.createTime=dateFormat.formatDate(new Date(),'yyyy年MM月dd日')
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      getSummaries(param) {
        // const {columns, data} = param;
        const sums = [];
        sums[0] = '汇总';
        sums[1] = '-';
        sums[2] = this.sumData.totalCount;
        sums[3] =  this.sumData.hasRentCount;
        sums[4] =  this.sumData.notRentCount;
        sums[5] =  this.sumData.totalAreas;
        sums[6] =  this.sumData.hasRentAreas;
        sums[7] =  this.sumData.notRentAreas;
        sums[8] =  this.sumData.occupancyRate;
        return sums;
      },
      async getData() {
        let res = await Http.getBoothOccupancyRate({})
        if (res.code == 200) {
          this.tableData = res.data.feeList
          // this.count = res.data.page.total
          this.sumData = res.data.sumData
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ws {
    width: 100%;
  }
</style>
